import React from "react"
import { PageProps } from "gatsby"
import { Footer } from "../components/Footer"
import Layout from "../components/Layout"
import configuration from "../configuration/privacy.json"
import Seo from "../components/Seo"
import { Navigation } from "../components/Navigation"

const Privacy: React.FC<PageProps> = props => {
  return (
    <Layout>
      <Seo
        title={configuration.seo.title}
        description={configuration.seo.description}
      />

      <Navigation />

      <div className="container max-w-6xl px-4 pb-10 mx-auto mt-10">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
            Politique de confidentialité
          </h1>
          <div className="mt-10 prose">
            <p>
              Comme pour de nombreux sites Web, nous recueillons les sessions de
              visite des visiteurs. Les informations que nous recueillons sont
              utilisées pour améliorer le contenu de nos pages Web et d'analyser
              quelles sont les pages les plus visitées. Les données ne sont
              jamais partagées ou vendues à d'autres organisations à des fins
              commerciales.
            </p>
            <h2>Cookies</h2>
            <p>
              Un cookie est une petite quantité de données, qui inclut souvent
              un identifiant unique anonyme. Ce cookie est stocké sur votre
              ordinateur et utilisé par le navigateur. Nous utilisons les
              cookies pour enregistrer les informations de la session en cours,
              mais nous ne l'utilisons pas de manière permanente.
            </p>
            <h2>Stockage de données</h2>
            <p>
              Nous utilisons Google Analytics afin de mieux comprendre quelles
              pages sont visitées et depuis quelles régions les visiteurs
              viennent.
            </p>
            <h2>Questions</h2>
            <p>
              Toute question concernant cette politique de confidentialité doit
              m'être adressée sur Twitter.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Privacy
